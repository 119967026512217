import React from "react"
import { Box, Flex, Text } from "theme-ui"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/Container"
import ArticlesList from "../components/ArticlesList"

const TagTemplate = ({ location, pageContext, data }) => {
  const { tag } = pageContext
  const image = getImage(data.file)

  // tod0o for the SEO props, we need to figure out how were handling images with tags.
  return (
    <Layout location={location} title={tag}>
      <Seo
        title={tag}
        description={`Find GoNation blog articles based on the tag ${tag}.`}
      />

      <Container style={{ pt: 5 }} width={1200}>
        <Box>
          <Flex>
            {data.file ? (
              <GatsbyImage style={{ width: "100%" }} image={image} alt={tag} />
            ) : (
              ""
            )}
          </Flex>
          <Box
            sx={{
              backgroundColor: "isWhite",
              maxWidth: 400,
              margin: "auto",
              mt: "-32px",
              position: "relative",
              zIndex: 2,
            }}
          >
            <Text
              as="h1"
              sx={{
                fontWeight: 400,
                textAlign: "center",
                paddingX: 2,
                paddingY: 3,
                textTransform: "uppercase",
              }}
            >
              Articles with the tag{" "}
              <Text as="span" sx={{ color: "primary" }}>
                #{tag}
              </Text>
            </Text>
          </Box>

          <Box sx={{ pt: 3 }}>
            <ArticlesList articles={data.allBlog.nodes} />
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export default TagTemplate

export const tagsQuery = graphql`
  query PostsByTags($tag: [String], $singularTag: String) {
    allBlog(filter: { tags: { in: $tag } }) {
      nodes {
        title
        subtitle
        media {
          cloudinaryId
          description
          name
        }
        readingTime
        tags
        id
        description
        ctaLink {
          url
          title
        }
        createdAt
        bodyHTML
        body
        author
      }
    }

    file(name: { eq: $singularTag }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
