import React from "react"
import { Flex, Box } from "theme-ui"
import Card from "./BlogCard"

const ArticlesList = ({ articles = [] }) => {
  const renderArticles = () =>
    articles.map(art => (
      <Box
        key={art.title}
        sx={{ width: ["100%", "100%", "50%", "50%", "33%"], padding: 3 }}
      >
        <Card data={art} />
      </Box>
    ))

  return <Flex sx={{ flexWrap: "wrap" }}>{renderArticles()}</Flex>
}

export default ArticlesList
